import React from "react";
import ReactDOM from "react-dom";
import Main from "./pages/main";
import 'antd/dist/antd.css'; 
import './index.css'

ReactDOM.render(
  <React.StrictMode>
    <Main />
  </React.StrictMode>,
  document.getElementById("root")
);
/*import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import "./product.css";
import Products from "./components/products";
import Create from "./components/create";
import Update from "./components/update";
import Login from "./components/login";
import Example from "./components/example";
import 'bootstrap/dist/css/bootstrap.min.css';
import "./index.css";
//import reportWebVitals from "./reportWebVitals";

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <div>
        <nav>
          <ul>
            <li><Link to="/login">Login</Link></li>
            <li><Link to="/">Products</Link></li>
          </ul>
        </nav>
        <Route exact path="/" component={Products} />
        <Route path="/create" component={Create} />
        <Route path="/login" component={Login} />
        <Route path="/update/:id" component={Update} />
        <Route path="/example" component={Example} />
      </div>
    </Router>
  </React.StrictMode>,
  document.getElementById("root")
);
*/
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
