import React from 'react'
import { Row, Col, Form, Input, Button, InputNumber,PageHeader, DatePicker, message } from 'antd';
const info = (mess, type = 'success') => {
    if(type === 'error') message.error(mess, 3);
    else message.success(mess, 3);
  };
const Create = () => {
    
const onFinish = (values) => {
    console.log('Success:', values);
    fetch("https://cargo.planet.ee/product", {
      method: "POST",
      body: JSON.stringify({
        name: values.name,
        price: values.price,
        sale_price: values.sale_price,
        sales_count: values.sales_count,
        sale_date: values.sale_date,
      }),
      headers: {
        "Content-type": "application/json; charset=UTF-8",
      },
    }).then((response) => {
      if (response.status === 201) {
        //alert("New product saved successfully");
        info('New product saved successfully');
      }
      //this.props.history.push("/products"); //return to main page
       
    });

     
};

const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
    info('Submit failed','error');
};
const [form] = Form.useForm();
const initialValues = {
    name: '',
    price: '',
    sale_price: '',
    sales_count: '',
    sale_date: ''
}
return (
    <>
    <PageHeader
        className="site-page-header"
        style={{border: '1px solid rgb(235, 237, 240)', marginBottom: '50px'}}
        title="Create new product"
    /> 
    <Row>
        <Col span={8}>
            <Form
                form={form}
                initialValues={initialValues}
                name="basic"
                labelCol={{span: 8}}
                wrapperCol={{
                    span: 16,
                }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
            >
      <Form.Item
        label="Product Name"
        name="name"
        rules={[
          {
            required: true,
            type: 'string',
            message: 'Please input product name!',
          },
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="Price"
        name="price"
        rules={[
          {
            required: true,
            message: 'Please input price!',
          },
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="Selling Price"
        name="sale_price"
        rules={[
          {
            required: true,
            message: 'Please input sale price!',
          },
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="Sales Count"
        name="sales_count"       
      >
        <InputNumber />
      </Form.Item>

      <Form.Item
        label="Sale Date"
        name="sale_date"
        rules={[
          {
            required: true,
            message: 'Please input sale date!',
          },
        ]}
      >
        <DatePicker format="DD-MM-YYYY" />
      </Form.Item>

      <Form.Item
        wrapperCol={{
          offset: 8,
          span: 16,
        }}
      >
        <Button type="primary" htmlType="submit">
          Submit
        </Button>
      </Form.Item>
    </Form>
      </Col>
      <Col span={16}></Col>
    </Row>
    
    </>
  );
};
export default Create