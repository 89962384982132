import React, {useState} from 'react';
import {Menu} from 'antd'
import { Link, useHistory } from 'react-router-dom';
import {messages} from '../components/messages'

const Nav = (props) => {
    const history = useHistory();
    
    // get me first path for menu
    var firstPath = window.location.pathname.split('/')[1];
    var loc = firstPath ? firstPath : 'main'
    const [path, setPath] = useState(loc)

    function logOut(){
        const axios = require('axios');
        axios.get('https://cargo.planet.ee/product/logout')
        .then(function (response) {
            // handle success
            console.log(response);
            props.setLogged(false);            
            messages('Logged out successfully!');
        })
        .catch(function (error) {
            // handle error
            console.log(error);
            messages('Logged out error!', 'error');
        })
        .then(function () {
            // always executed
            setPath('login')
            history.push("/login");
        });
    }
    const handleClick = (e) => {
        if(e.key === 'logout') {
            logOut()
        }
        else setPath(e.key);
        };
    return (
        <Menu theme="light" mode="horizontal" 
        selectedKeys={[path]} style={{background:'transparent'}} onClick={handleClick}>
        <Menu.Item key='main'><Link to="/">Home</Link></Menu.Item>
        <Menu.Item key='about'><Link to="/about">About</Link></Menu.Item>
        <Menu.Item key='products'><Link to="/products">Products</Link></Menu.Item>
        <Menu.Item key='login'><Link to="/login">Login</Link></Menu.Item>
        <Menu.Item key='logout'>Logout</Menu.Item>
      </Menu>
    )
}
export default Nav