import React, {useState} from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import { Layout, Breadcrumb, PageHeader } from 'antd';
import Login from './login';
import Products from './products';
import Create from './create';
import Nav from '../components/Nav'

import {messages} from '../components/messages'

const { Header, Content, Footer } = Layout;

const Home = () => {
  function postOnClick(){
    const axios = require('axios');
    axios.get('https://cargo.planet.ee/product/test')
    .then(function (response) {
      if(response.data) console.log('data:',response.data);
      if(response.message) console.log('message:',response.message);
      console.log('status:',response.status);
    }).catch(function (error) {
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.log('error data:', error.response.data);
        console.log('error status:',error.response.status);
        console.log('error headers', error.response.headers);
      } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        console.log('Error.request:', error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log('Error:', error.message);
      }
      console.log(error.config);
    });
  }
  return (
  <> 
  <PageHeader
    className="site-page-header"
    style={{border: '1px solid rgb(235, 237, 240)'}}
    title="Home"
    subTitle="This is a home subtitle"
    />
    <button onClick={postOnClick}>Post</button>
    </>
  )

}

const About = () => <PageHeader
className="site-page-header"
style={{border: '1px solid rgb(235, 237, 240)'}}
title="Abut"
subTitle="This is a about subtitle"
/>;



const App = () => {

  const [logged, setLogged] = useState(false);
  


const onFinish = (values) => {
  console.log('Success:', values);
  fetch("https://cargo.planet.ee/product/login", {
    credentials: 'include',
    method: "POST",
    body: JSON.stringify({
      identity: values.identity,
      password: values.password,
      remember: values.remember
    }),
    headers: {
      "Content-type": "application/json; charset=UTF-8",
    },
  }).then(response => response.json())  // convert to json
  .then(result => {
      console.log(result)
      if(result.status === 200) {
          setLogged(true);
          messages('Logged in succesfully!')
      }
      else {
          setLogged(false);
          messages('Couldn\'t log in, login data incorrect!','error')
      }
  })   
  .catch(err => {
    console.log('Log in Failed', err)
    messages('log in Failed'+err, 'error')
  }); // Catch errors

};
    return (    

  <BrowserRouter>
    <Layout className="layout" style={{background:'transparent'}}>
    <Header style={{background:'transparent'}}>
      <div className="logo" />
      <Nav setLogged={setLogged}/>
    </Header>
    <Content style={{ padding: '0 50px',minHeight:'calc(100vh - 70px)' }}>
      <Breadcrumb style={{ margin: '16px 0' }}>
        <Breadcrumb.Item>Home</Breadcrumb.Item>
      </Breadcrumb>
      <div className="site-layout-content">
        <p>Logged {logged ? 'in' : 'out' }</p>
        <Switch>
            <Route exact path="/">
              <Home />
            </Route>
            <Route path="/about">
              <About />
            </Route>    
            <Route path="/products">
              <Products />
            </Route> 
            <Route path="/create" component={Create} />       
            <Route path="/login">
              <Login onFinish={onFinish} logged={logged}/>
            </Route>            
          </Switch>
      </div>
    </Content>
    <Footer style={{ textAlign: 'center' }}>Ant Design ©2018 Created by Ant UED</Footer>
  </Layout>    
  </BrowserRouter>
)}

export default App;
