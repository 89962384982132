import React, {useState, useEffect} from 'react'
import { Row, Col, PageHeader, Table, Space, Button, Popconfirm } from 'antd';

import { Link } from "react-router-dom";

const Products = () => {
  const columns = [
    {
      title: 'Id',
      dataIndex: 'id'
    },
    {
      title: 'Name',
      dataIndex: 'name'
    },
    {
      title: 'Price',
      dataIndex: 'price'
    },
    {
      title: 'Sale Price',
      dataIndex: 'sale_price'
    },
    {
      title: 'Sale Date',
      dataIndex: 'sale_date'
    },
    {
      title: 'Sales Count',
      dataIndex: 'sales_count'
    },
    {
      title: 'Action',
      dataIndex:'id',
      key: 'action',
      render: (text, item) => (        
        <Space size="middle">
          <Link to={`/update/${item.id}`}>Edit</Link>
          <Popconfirm
            title="Are you sure to delete this product?"
            onConfirm={() => deleteProduct(item.id)}
            okText="Yes"
            cancelText="No"
          >
              <a href="#delete">Delete</a> 
          </Popconfirm>
          
        </Space>
      ),
    },
  ];   
 const [products, setProducts] = useState(false);

 useEffect(() => {
  fetch("https://cargo.planet.ee/product", {/*credentials: 'include'*/})
  .then(response => response.json())  // convert to json
  .then(result => {
    setProducts(result)    
  })   
  .catch(err => console.log('Product request Failed', err)); // Catch errors      
  
    
},[]);

function deleteProduct(id){
  //e.preventDefault();
  if (id) {
    fetch("https://cargo.planet.ee/product/" + id, {
      credentials: 'include', method: "DELETE",
    }).then((response) => {
      if (response.status === 200) {
        alert("Product deleted successfully");
        fetch("https://cargo.planet.ee/product")
        .then(response => response.json())  // convert to json
        .then(result => {
          setProducts(result)    
        })   
        .catch(err => console.log('Product request Failed', err)); // Catch errors 
         // this.props.history.push("/");
      }
      
    });
  }
  console.log('deleted'+id)  
};
    
return (
    <>
    <PageHeader
        className="site-page-header"
        style={{border: '1px solid rgb(235, 237, 240)', marginBottom: '50px'}}
        title="Products"
        subTitle="do something with this subtitle"
        extra={[
          <Button key="1" type="primary">
            <Link to="/create">Add new</Link>
          </Button>
        ]}
    /> 
    <Row>
      <Col span={16}> 
        <Table dataSource={products} columns={columns} rowKey="id"/>
      </Col>
      <Col span={8}></Col>
    </Row>
    
    </>
  )
};
export default Products