import React from 'react'
import { Row, Col, Form, Input, Button, Checkbox,PageHeader } from 'antd';
import {messages} from '../components/messages'

const Login = (props) => {
    
    //const [logged, setLogged] = useState(false);
    //const [message, setMessage] = useState(false)



const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
    messages('Submit failed','error');
};

return (
    <>
    <PageHeader
        className="site-page-header"
        style={{border: '1px solid rgb(235, 237, 240)', marginBottom: '50px'}}
        title="Login"
        subTitle="Login subtitle"
    /> 
    Logged: {props.logged ? 'yes': 'no'} 
    <Row>
        <Col span={8}>
            <Form
                name="basic"
                labelCol={{span: 8}}
                wrapperCol={{
                    span: 16,
                }}
                initialValues={{
                    remember: true,
                }}
                onFinish={props.onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
            >
      <Form.Item
        label="Username"
        name="identity"
        rules={[
          {
            required: true,
            message: 'Please input your username!',
          },
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="Password"
        name="password"
        rules={[
          {
            required: true,
            message: 'Please input your password!',
          },
        ]}
      >
        <Input.Password />
      </Form.Item>

      <Form.Item
        name="remember"
        valuePropName="checked"
        wrapperCol={{
          offset: 8,
          span: 16,
        }}
      >
        <Checkbox>Remember me</Checkbox>
      </Form.Item>

      <Form.Item
        wrapperCol={{
          offset: 8,
          span: 16,
        }}
      >
        <Button type="primary" htmlType="submit">
          Submit
        </Button>
      </Form.Item>
    </Form>
      </Col>
      <Col span={16}></Col>
    </Row>
    
    </>
  );
};
export default Login